import { SearchEditorProvider } from '@odo/contexts/search/editor';
import { SearchFiltersProvider } from '@odo/contexts/search/filters';
import type { ReactNode } from 'react';
import { BulkEditContextProvider } from '@odo/contexts/search/bulk-edit';

const SearchProviders = ({ children }: { children: ReactNode }) => (
  <SearchFiltersProvider>
    <BulkEditContextProvider>
      <SearchEditorProvider>{children}</SearchEditorProvider>
    </BulkEditContextProvider>
  </SearchFiltersProvider>
);

export default SearchProviders;
