import type { ScreenId } from '@odo/screens/deal/editor/types';
import type { EditorProductInterface } from '@odo/types/portal';

interface BaseChange {
  fieldId: string;
  label: string; // might be shown to users later in the list of changes
  screen?: ScreenId;
  /**
   * NOTE: the apply function should be as fast as possible, as it may be undone and redone multiple times.
   * It functions similarly to a reducer, in that it should be idempotent.
   */
  apply: (to: EditorProductInterface, meta?: unknown) => void;
  meta?: unknown;
  /**
   * Can be added to allow cleanup of extra objects if the change gets reverted elsewhere.
   *
   * Example: if you upload an image and add a change for it,
   * but then a user resets their changes,
   * you might want to go and delete that image from source.
   */
  cleanup?: (meta?: BaseChange['meta']) => Promise<void>;
}

export type ChangeInput = BaseChange;
export type ProductChange = BaseChange & {
  /**
   * NOTE: this is a uuid used for tracking the change internally.
   */
  id: string;
};

export interface ChangeLock {
  status: boolean;
  reason?: string;
  withNotice?: boolean;
}

export enum EventType {
  change = 'change',
  reset = 'reset',
  undoReset = 'undo-reset',
  finalizeReset = 'finalize-reset',
  lock = 'lock',
  unlock = 'unlock',
}

/**
 * NOTE: if we need to send parameters with an event it must get a custom definition like `change`.
 * But otherwise it will be automatically covered by the fallback.
 */
export type EventListener =
  | {
      type: EventType.change;
      callback: (change: ProductChange) => void;
    }
  | {
      type: EventType;
      callback: () => void;
    };

export interface ProductEditorContextType {
  currentProduct: EditorProductInterface;
  change: (change: ChangeInput) => {
    /**
     * Can be used to undo a change from outside the provider.
     *
     * Example: if you automatically change one field in response to another being updated by the user,
     * then you might want to give the user the option to undo that automatic change.
     */
    undo: () => void;
  };
  canReset: boolean;
  hasChanges: boolean;
  reset: () => void;
  getChanges: () => ProductChange[]; // potentially allow consumers to get changes for saving/etc.
  resetEditor: () => void;

  /**
   * Can be used to prevent changes while saving or doing other tasks that shouldn't be interfered with.
   */
  setLock: (
    status: boolean,
    options?: { reason?: string; withNotice?: boolean }
  ) => void;

  addEventListener: (event: EventListener) => void;
  removeEventListener: (event: EventListener) => void;

  changedScreens: ScreenId[];
}
