import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import EditorNav from '@odo/components/widgets/editor-nav';
import {
  useCurrentProduct,
  useProductEditor,
} from '@odo/contexts/product-editor';
import { Screen } from '@odo/screens/deal/editor/types';
import type { Validator } from '@odo/screens/deal/editor/types';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { buyerAndSupplierValidators } from '@odo/screens/deal/editor/buyer-and-supplier';
import { productValidators } from '@odo/screens/deal/editor/product';
import { conditionsAndCategoryValidators } from '@odo/screens/deal/editor/conditions-and-category';
import { priceAndCustomOptionsValidators } from '@odo/screens/deal/editor/price-and-custom-options';
import { shippingAndInventoryValidators } from '@odo/screens/deal/editor/shipping-and-inventory';
import { imagesAndVideosValidators } from '@odo/screens/deal/editor/images-and-videos';
import { summaryValidators } from '@odo/screens/deal/editor/summary';
import { BASE_PATH } from '@odo/screens/deal/editor/constants';
import { useDealId } from '@odo/screens/deal/editor/hooks';

const nav: { id: Screen; label: string; validators: Validator[] }[] = [
  {
    id: Screen.buyerAndSupplier,
    label: 'Buyer & Supplier',
    validators: buyerAndSupplierValidators,
  },
  { id: Screen.product, label: 'Product', validators: productValidators },
  {
    id: Screen.conditionsAndCategory,
    label: 'Conditions & Categories',
    validators: conditionsAndCategoryValidators,
  },
  {
    id: Screen.priceAndCustomOptions,
    label: 'Price & Custom Options',
    validators: priceAndCustomOptionsValidators,
  },
  {
    id: Screen.shippingAndInventory,
    label: 'Shipping & Inventory',
    validators: shippingAndInventoryValidators,
  },
  {
    id: Screen.imagesAndVideos,
    label: 'Images & Videos',
    validators: imagesAndVideosValidators,
  },
  { id: Screen.summary, label: 'Summary', validators: summaryValidators },
];

const Nav = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentProduct = useCurrentProduct();
  const { isDraft } = useDealId();
  const { changedScreens } = useProductEditor();

  const [active, setActive] = useState<string | undefined>();
  const [statuses, setStatuses] = useState({});

  const go = useCallback(
    (to: string) => {
      const navItem = nav.find(({ id }) => id === to);
      if (!navItem) return;

      // TODO: scroll to top?

      setActive(to);
      navigate(
        generatePath(`${BASE_PATH}/:dealId?/${navItem.id}`, {
          dealId: params?.dealId || null,
        })
      );
    },
    [navigate, params]
  );

  const navItems = useMemo(() => {
    return nav.map(n => ({
      ...n,
      isActive: active === n.id,
      status: statuses[n.id] || undefined,
      onClick: () => go(n.id),
      ...(!isDraft &&
        changedScreens.includes(n.id) && { hasUnsavedChanges: true }),
    }));
  }, [active, statuses, go, isDraft, changedScreens]);

  /**
   * Update active item on location change.
   */
  useEffect(() => {
    const activeNavItem = nav.find(({ id }) => location.pathname.endsWith(id));
    if (activeNavItem) {
      setActive(activeNavItem.id);
    }
  }, [location]);

  /**
   * Re-run validators on product change.
   */
  useEffect(() => {
    setStatuses(statuses => {
      const nextStatuses = { ...statuses };
      nav.forEach(n => {
        nextStatuses[n.id] = validateProduct(
          currentProduct,
          n.validators
        ).status;
      });
      return nextStatuses;
    });
  }, [currentProduct]);

  return <EditorNav items={navItems} />;
};

export default Nav;
