import type { GridProduct } from '@odo/components/search/types';
import LabelledGrid from '@odo/components/elements/labelled-grid';
import { zarFormatter } from '@odo/utils/currency';

const savingsPercent = (price, retail): number | undefined => {
  if (retail <= 0) return 0;
  if (retail - price <= 0) return undefined;
  return Math.round((1 - price / retail) * 100);
};

const savingsRands = (price, retail) => retail - price;

const Pricing = ({ data }: { data: GridProduct }) => {
  const savingsAsPercent = savingsPercent(data.price, data.retail);
  return (
    <LabelledGrid
      rows={[
        { label: 'Cost', value: data.cost ? zarFormatter(data.cost) : '' },
        { label: 'Price', value: data.price ? zarFormatter(data.price) : '' },
        {
          label: 'Retail',
          value: data.retail ? zarFormatter(data.retail) : '',
        },
        {
          label: 'Savings',
          value: data.isSavingsInRands
            ? `${zarFormatter(savingsRands(data.price, data.retail))}`
            : savingsAsPercent
            ? `${savingsAsPercent}%`
            : 'n/a',
        },
      ]}
    />
  );
};

export default Pricing;
