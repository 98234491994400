import type { ImageInput } from '@odo/graphql/product/images';
import { mutationCreateProductImage } from '@odo/graphql/product/images';
import type { BaseProductImage } from '@odo/types/api-new';
import type {
  EditorProductImage,
  EditorProductInterface,
} from '@odo/types/portal';
import { isNewId } from '@odo/utils/uuid';
import { dismiss, loading } from '@odo/utils/toast';

// TODO: move this to a typed config file once we have a clear idea of how we want to do our config
const concurrentImageUploadsEnabled =
  process.env.REACT_APP_CONCURRENT_IMAGE_UPLOADS === 'true';

const IMAGE_UPLOAD_MAX_ATTEMPTS = 3;
const IMAGE_UPLOAD_TOAST_ID = 'product-image-upload';

/**
 * NOTE: we'd use normal async/await, but we can't throw/return from within the FileReader load event callback,
 * so instead we're gonna use a promise and resolve/reject, then we can use async/await when calling this function.
 */
export const readFileAsBase64 = async ({ file }: { file: File }) => {
  return new Promise<{ mimeType: string; base64: string }>(
    (resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.addEventListener('load', async e => {
          if (typeof e.target?.result !== 'string') {
            reject('Failed to read file as string');
            return;
          }

          const dataMatch = e.target.result.match(/^data:(.+);.+,(.+)/);
          if (!dataMatch) {
            reject('Failed to match data string');
            return;
          }

          const [, mimeType, base64] = dataMatch;
          if (!mimeType || !base64) {
            reject('data string is missing mimeType or base64');
            return;
          }

          resolve({ mimeType, base64 });
        });

        reader.addEventListener('error', () => reject('Failed to read file'));

        reader.readAsDataURL(file);
      } catch (e) {
        reject(
          e instanceof Error && typeof e.message === 'string'
            ? e.message
            : typeof e === 'string'
            ? e
            : 'Failed to read file as base64'
        );
      }
    }
  );
};

export const uploadImage = async ({
  id,
  image,
  onSuccess,
  onFailure,
}: {
  id: string;
  image: EditorProductImage;
  onSuccess?: (image: BaseProductImage) => void;
  onFailure?: (image: EditorProductImage, message: string) => void;
}) => {
  if (!image.file) {
    throw new Error('Could not find image file');
  }

  try {
    const { mimeType, base64 } = await readFileAsBase64({ file: image.file });

    let error = 'Failed to upload image after multiple attempts';
    try {
      let completed = false;
      let attempts = 0;

      const imageInput: ImageInput = {
        mimetype: mimeType,
        image: base64,
        filename: image.file?.name || image.id,
        position: image.position,
        imageTypes: image.imageTypes,
        label: image.label,
        excludeImageTypes: image.isHidden ? 1 : 0,
      };

      do {
        attempts++;

        const createdImage = await mutationCreateProductImage({
          id,
          image: imageInput,
        });

        if (createdImage) {
          onSuccess && onSuccess(createdImage);
          completed = true;
          return createdImage;
        }
      } while (!completed && attempts < IMAGE_UPLOAD_MAX_ATTEMPTS);
    } catch (e) {
      if (e instanceof Error && typeof e.message === 'string') {
        error = e.message;
      }
    }

    throw new Error(error);
  } catch (e) {
    const message =
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : typeof e === 'string'
        ? e
        : 'Failed to upload image as data url';

    onFailure && onFailure(image, message);
    throw new Error(message);
  }
};

export const uploadAllNewImages = async ({
  id,
  images,
}: {
  id: string;
  images: EditorProductInterface['images'];
}) => {
  const imageUploadSuccesses: BaseProductImage[] = [];
  const imageUploadFailures: {
    image: EditorProductImage;
    message: string;
  }[] = [];

  const imageUploads = (images || []).filter(
    image => isNewId(image.id) && image.file && !image.shouldDelete
  );
  if (imageUploads.length > 0) {
    const totalImages = imageUploads.length;

    const loadingToastId = loading(`Uploading images: 0/${totalImages}`, {
      id: IMAGE_UPLOAD_TOAST_ID,
    });

    if (concurrentImageUploadsEnabled) {
      await Promise.allSettled(
        imageUploads.map(image =>
          uploadImage({
            id,
            image,
            onSuccess: createdImage => {
              imageUploadSuccesses.push(createdImage);

              loading(
                `Uploading images: ${imageUploadSuccesses.length}/${totalImages}`,
                { id: IMAGE_UPLOAD_TOAST_ID }
              );
            },
            onFailure: (image, message) =>
              imageUploadFailures.push({ image, message }),
          })
        )
      );
    } else {
      for (const image of imageUploads) {
        try {
          const createdImage = await uploadImage({ id, image });
          imageUploadSuccesses.push(createdImage);

          loading(
            `Uploading images: ${imageUploadSuccesses.length}/${totalImages}`,
            { id: IMAGE_UPLOAD_TOAST_ID }
          );
        } catch (e) {
          imageUploadFailures.push({
            image,
            message:
              e instanceof Error && typeof e.message === 'string'
                ? e.message
                : typeof e === 'string'
                ? e
                : 'Failed to upload image',
          });
        }
      }
    }

    dismiss(loadingToastId);
  }

  return { imageUploadSuccesses, imageUploadFailures };
};
