import {
  ButtonStrip,
  Checkbox,
  Fieldset,
  InputNumericMasked,
  Select,
  Switch,
} from '@odo/components/elements/form-fields';
import { Box, Flex, Grid, GridItem } from '@odo/components/elements/layout';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import {
  inventorySectionValidators,
  customerShippingSectionValidators,
  deliverySectionValidators,
} from '@odo/screens/deal/editor/shipping-and-inventory/validators';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import Divider from '@odo/components/elements/divider';
import { useMemo } from 'react';
import { calcVolumetricWeight } from '@odo/helpers/calculations/shipping';
import { Text } from '@odo/components/elements/typography';
import PackagePreview from '@odo/screens/deal/editor/shipping-and-inventory/widgets/package-preview';
import { OriginalStock } from '@odo/screens/deal/editor/widgets/shared-fields';
import { excelRounding } from '@odo/helpers/calculations/general';
import { isNumber } from '@odo/types/guards';

const SlideOutWrapper = styled(Box)`
  transition: all 0.25s ease-in-out;
  transform: translateY(-15px);
  opacity: 0;
  height: 0;
  pointer-events: none;

  &.active {
    transform: translateX(0);
    opacity: 1;
    margin-top: 24px;
    height: 57px;
    pointer-events: unset;
  }
`;

const InventorySection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    inventorySectionValidators
  ).status;

  return (
    <SectionWrapper title="Inventory" status={status}>
      <Select
        label="Inventory Status"
        value={
          currentProduct.inventory?.isInStock ? 'in-stock' : 'out-of-stock'
        }
        onChange={e => {
          const isInStock = e.target.value === 'in-stock';
          change({
            fieldId: 'inventory.isInStock',
            label: 'Inventory Status',
            screen: 'shipping-and-inventory',
            apply: to => (to.inventory = { ...to.inventory, isInStock }),
          });
        }}
        options={[
          { id: 'out-of-stock', value: 'out-of-stock', label: 'Out of Stock' },
          { id: 'in-stock', value: 'in-stock', label: 'In Stock' },
        ]}
      />

      <OriginalStock screen="shipping-and-inventory" />

      <InputNumericMasked
        label="Quantity Available"
        value={currentProduct.inventory?.qty?.string || ''}
        type="integer"
        allowNegative
        onChange={qty => {
          change({
            fieldId: 'inventory.qty',
            label: 'Quantity Available',
            screen: 'shipping-and-inventory',
            apply: to => (to.inventory = { ...to.inventory, qty }),
          });
        }}
        selectOnFocus
      />

      <Box>
        <Checkbox
          label="Use configured minimum quantity in cart?"
          checked={!!currentProduct.inventory?.useConfigMinSaleQty}
          onChange={e => {
            const checked = !!e.target.checked;
            change({
              fieldId: 'inventory.useConfigMinSaleQty',
              label: 'Use configured minimum quantity in cart?',
              screen: 'shipping-and-inventory',
              apply: to =>
                (to.inventory = {
                  ...to.inventory,
                  useConfigMinSaleQty: checked,
                }),
            });
          }}
        />

        <SlideOutWrapper
          className={
            !currentProduct.inventory?.useConfigMinSaleQty
              ? 'active'
              : undefined
          }
        >
          <InputNumericMasked
            label="Min Quantity In Cart"
            value={currentProduct.inventory?.minSaleQuantity?.string || ''}
            tabIndex={
              currentProduct.inventory?.useConfigMinSaleQty ? -1 : undefined
            }
            type="integer"
            onChange={minSaleQuantity => {
              change({
                fieldId: 'inventory.minSaleQuantity',
                label: 'Min Quantity In Cart',
                screen: 'shipping-and-inventory',
                apply: to =>
                  (to.inventory = { ...to.inventory, minSaleQuantity }),
              });
            }}
            selectOnFocus
          />
        </SlideOutWrapper>
      </Box>

      <Box>
        <Checkbox
          label="Use configured maximum quantity in cart?"
          checked={!!currentProduct.inventory?.useConfigMaxSaleQty}
          onChange={e => {
            const checked = !!e.target.checked;
            change({
              fieldId: 'inventory.useConfigMaxSaleQty',
              label: 'Use configured maximum quantity in cart?',
              screen: 'shipping-and-inventory',
              apply: to =>
                (to.inventory = {
                  ...to.inventory,
                  useConfigMaxSaleQty: checked,
                }),
            });
          }}
        />

        <SlideOutWrapper
          className={
            !currentProduct.inventory?.useConfigMaxSaleQty
              ? 'active'
              : undefined
          }
        >
          <InputNumericMasked
            label="Max Quantity In Cart"
            value={currentProduct.inventory?.maximumSaleQuantity?.string || ''}
            tabIndex={
              currentProduct.inventory?.useConfigMaxSaleQty ? -1 : undefined
            }
            type="integer"
            onChange={maximumSaleQuantity => {
              change({
                fieldId: 'inventory.maximumSaleQuantity',
                label: 'Max Quantity In Cart',
                screen: 'shipping-and-inventory',
                apply: to =>
                  (to.inventory = { ...to.inventory, maximumSaleQuantity }),
              });
            }}
            selectOnFocus
          />
        </SlideOutWrapper>
      </Box>

      <Divider />

      <Checkbox
        label="Apply maximum sale quantity to product options?"
        checked={!!currentProduct.inventory?.isApplyMaxSaleQtyToProductOptions}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'inventory.isApplyMaxSaleQtyToProductOptions',
            label: 'Apply maximum sale quantity to product options?',
            screen: 'shipping-and-inventory',
            apply: to =>
              (to.inventory = {
                ...to.inventory,
                isApplyMaxSaleQtyToProductOptions: checked,
              }),
          });
        }}
      />

      <Checkbox
        label="Apply maximum sale quantity to customer profile?"
        checked={!!currentProduct.inventory?.isApplyMaxSaleQtyCustomerProfile}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'inventory.isApplyMaxSaleQtyCustomerProfile',
            label: 'Apply maximum sale quantity to customer profile?',
            screen: 'shipping-and-inventory',
            apply: to =>
              (to.inventory = {
                ...to.inventory,
                isApplyMaxSaleQtyCustomerProfile: checked,
              }),
          });
        }}
      />
    </SectionWrapper>
  );
};

const DeliverySection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    deliverySectionValidators
  ).status;

  const supplierRepacksOptions = useAttributeOptions(
    AttributeCode.supplierRepacks
  );
  const areaOptions = useAttributeOptions(AttributeCode.area);
  const buyInStockTypeOptions = useAttributeOptions(
    AttributeCode.buyInStockType
  );

  const areaOptionsFiltered = useMemo(
    () => areaOptions.filter(a => a.key !== 'NONE'),
    [areaOptions]
  );

  return (
    <SectionWrapper title="Delivery" status={status}>
      <Select
        label="Supplier Repacks"
        value={currentProduct.supplierRepacks?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const supplierRepacks = supplierRepacksOptions.find(
            supplierRepacks => supplierRepacks.value === value
          );
          change({
            fieldId: 'supplierRepacks',
            label: 'Supplier Repacks',
            screen: 'shipping-and-inventory',
            apply: to => {
              to.supplierRepacks = supplierRepacks
                ? {
                    id: supplierRepacks.value,
                    label: supplierRepacks.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...supplierRepacksOptions
            .filter(option => option.value.toLowerCase() !== 'none')
            .map(option => ({
              id: option.value,
              value: option.value,
              label: option.label,
            })),
        ]}
        required
      />

      <ButtonStrip
        label="Area"
        hue="blue"
        selectedOption={{
          id: currentProduct.area?.id || '',
          label: currentProduct.area?.label || '',
        }}
        options={areaOptionsFiltered.map(option => ({
          id: option.value,
          label: option.label,
        }))}
        onSelect={option => {
          change({
            fieldId: 'area',
            label: 'Area',
            screen: 'shipping-and-inventory',
            apply: to => (to.area = { id: option.id, label: option.label }),
          });
        }}
      />

      <ButtonStrip
        label="Buy-in Stock Type"
        hue="blue"
        selectedOption={{
          // NOTE: API returns null for buyInStockType when NONE (unlike tax class)
          id: currentProduct.buyInStockType?.id || 'NONE',
          label: currentProduct.buyInStockType?.label || '',
        }}
        options={buyInStockTypeOptions.map(option => ({
          id: option.value,
          label: option.label,
        }))}
        onSelect={option => {
          change({
            fieldId: 'buyInStockType',
            label: 'Buy-in Stock Type',
            screen: 'shipping-and-inventory',
            apply: to =>
              (to.buyInStockType = { id: option.id, label: option.label }),
          });
        }}
      />
    </SectionWrapper>
  );
};

const CustomerShippingSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    customerShippingSectionValidators
  ).status;

  const customerDeliveryTimeOptions = useAttributeOptions(
    AttributeCode.customerDeliveryTime
  );

  const greaterWeight = useMemo(() => {
    let volumetricWeight: number | undefined;
    if (
      isNumber(currentProduct.length?.number) &&
      isNumber(currentProduct.width?.number) &&
      isNumber(currentProduct.height?.number)
    ) {
      volumetricWeight = excelRounding(
        calcVolumetricWeight(
          currentProduct.length?.number || 0,
          currentProduct.width?.number || 0,
          currentProduct.height?.number || 0
        ),
        'up',
        3
      );
    }

    const weight = isNumber(currentProduct.weight?.number)
      ? currentProduct.weight?.number
      : undefined;

    if (!weight && !volumetricWeight) return undefined;

    return (volumetricWeight || 0) > (weight || 0)
      ? `Greater Weight: ${volumetricWeight}kg (Volumetric Weight)`
      : `Greater Weight: ${weight}kg (Manual Weight)`;
  }, [
    currentProduct.length?.number,
    currentProduct.width?.number,
    currentProduct.height?.number,
    currentProduct.weight?.number,
  ]);

  return (
    <SectionWrapper title="Customer Shipping" status={status}>
      <Switch
        label="Does shipping apply?"
        width="3.5em"
        checked={!!currentProduct.isShippingApplied}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isShippingApplied',
            label: 'Does shipping apply?',
            screen: 'shipping-and-inventory',
            apply: to => (to.isShippingApplied = checked),
          });

          if (checked || currentProduct.shippingCost?.number === null) return;

          // not optional, so we won't allow users to undo
          change({
            fieldId: 'clearShippingCost',
            label: 'Clear Shipping Cost',
            screen: 'shipping-and-inventory',
            apply: to => (to.shippingCost = { string: '', number: null }),
          });
        }}
      />

      <Fieldset>
        <legend>Dimensions (cm)</legend>
        <Grid gap="24px" gridTemplateColumns={['1fr', '1fr 1fr']}>
          <InputNumericMasked
            label="Length"
            value={currentProduct.length?.string || ''}
            type="decimal"
            onChange={length => {
              change({
                fieldId: 'length',
                label: 'Length (cm)',
                screen: 'shipping-and-inventory',
                apply: to => (to.length = length),
              });
            }}
            required
            selectOnFocus
          />

          <InputNumericMasked
            label="Width"
            value={currentProduct.width?.string || ''}
            type="decimal"
            onChange={width => {
              change({
                fieldId: 'width',
                label: 'Width (cm)',
                screen: 'shipping-and-inventory',
                apply: to => (to.width = width),
              });
            }}
            required
            selectOnFocus
          />

          <InputNumericMasked
            label="Height"
            value={currentProduct.height?.string || ''}
            type="decimal"
            onChange={height => {
              change({
                fieldId: 'height',
                label: 'Height (cm)',
                screen: 'shipping-and-inventory',
                apply: to => (to.height = height),
              });
            }}
            required
            selectOnFocus
          />
        </Grid>
      </Fieldset>

      <GridItem>
        <Fieldset>
          <legend>Manual Weight (kg)</legend>
          <Flex flexDirection="column" gap={2}>
            <InputNumericMasked
              value={currentProduct.weight?.string || ''}
              type="decimal"
              onChange={weight => {
                change({
                  fieldId: 'weight',
                  label: 'Manual Weight (kg)',
                  screen: 'shipping-and-inventory',
                  apply: to => (to.weight = weight),
                });
              }}
              selectOnFocus
            />
          </Flex>
        </Fieldset>

        {!!greaterWeight && (
          <Text color={cssColor('grey')} fontStyle="italic" pl={[0, 3]} pt={2}>
            {greaterWeight}
          </Text>
        )}
      </GridItem>

      <Checkbox
        label="Must be shipped individually?"
        checked={!!currentProduct.isShippedIndividually}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isShippedIndividually',
            label: 'Must be shipped individually?',
            screen: 'shipping-and-inventory',
            apply: to => (to.isShippedIndividually = checked),
          });
        }}
      />

      <Checkbox
        label="Supplier delivers?"
        checked={!!currentProduct.isDeliveredBySupplier}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isDeliveredBySupplier',
            label: 'Supplier delivers?',
            screen: 'shipping-and-inventory',
            apply: to => (to.isDeliveredBySupplier = checked),
          });
        }}
      />

      <InputNumericMasked
        label="Manual Shipping Cost (Optional, VAT Inclusive)"
        disabled={!currentProduct.isShippingApplied}
        value={currentProduct.shippingCost?.string || ''}
        type="currency"
        onChange={shippingCost => {
          change({
            fieldId: 'shippingCost',
            label: 'Manual Shipping Cost (Optional, VAT Inclusive)',
            screen: 'shipping-and-inventory',
            apply: to => (to.shippingCost = shippingCost),
          });
        }}
        selectOnFocus
      />

      <Select
        label="Customer Delivery Time"
        value={currentProduct.customerDeliveryTime?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const customerDeliveryTime = customerDeliveryTimeOptions.find(
            customerDeliveryTime => customerDeliveryTime.value === value
          );

          change({
            fieldId: 'customerDeliveryTime',
            label: 'Customer Delivery Time',
            screen: 'shipping-and-inventory',
            apply: to => {
              to.customerDeliveryTime = customerDeliveryTime
                ? {
                    id: customerDeliveryTime.value,
                    label: customerDeliveryTime.label,
                  }
                : undefined;
            },
          });

          const requireShippedIndividually =
            !currentProduct.isShippedIndividually &&
            (customerDeliveryTime?.metadata || []).find(
              ({ key }) => key === 'require_shipped_individually'
            )?.value === '1';

          change({
            fieldId: 'requireShippedIndividually',
            label: `${
              requireShippedIndividually ? 'Require' : "Don't Require"
            } Individual Shipping`,
            screen: 'shipping-and-inventory',
            apply: to =>
              (to.isShippedIndividually = requireShippedIndividually),
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...customerDeliveryTimeOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
      />
    </SectionWrapper>
  );
};

const PackageSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Package Preview">
      {isNumber(currentProduct.width?.number) &&
      isNumber(currentProduct.height?.number) &&
      isNumber(currentProduct.length?.number) ? (
        <PackagePreview
          // TODO: when we move to a later version of TS, we won't need the `|| 0` anymore
          // it looks like the narrowing from above will be improved in 5.3
          // I can't find reference to this in the release notes
          // but I've tested by going through version upgrades one at a time in a separate project
          // and 5.3 was when this started working as desired
          width={currentProduct.width?.number || 0}
          height={currentProduct.height?.number || 0}
          length={currentProduct.length?.number || 0}
        />
      ) : (
        <Text color={cssColor('grey')} fontStyle="italic">
          Cannot display package preview with missing dimensions.
        </Text>
      )}
    </SectionWrapper>
  );
};

const ShippingAndInventoryScreen = () => (
  <ErrorBoundary>
    <Grid
      gridTemplateColumns={['1fr', '1fr 1fr', null, '1fr 2fr']}
      alignItems="flex-start"
      gap={[3, 4]}
    >
      <Grid gap={[3, 4]}>
        <InventorySection />
        <DeliverySection />
      </Grid>

      <Grid gap={[3, 4]} gridTemplateColumns={['1fr', null, null, '1fr 1fr']}>
        <CustomerShippingSection />
        <PackageSection />
      </Grid>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default ShippingAndInventoryScreen;
