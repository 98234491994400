import { useCallback, useEffect, useRef, useState } from 'react';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import {
  attachClosestEdge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { EditorProductImage } from '@odo/types/portal';
import { useChangeProduct } from '@odo/contexts/product-editor';
import { createRoot } from 'react-dom/client';

export const DRAG_REORDER_INSTANCE_ID = 'drag-reorder-images';

/**
 * NOTE: these hooks could technically be made more generic,
 * but considering how straightforward the underlying utils are and how specific some of the code here is,
 * I've decided to avoid an early abstraction that might never be needed.
 *
 * We can reconsider this later if we find that we can make a generic version for multiple uses.
 */
export const useDragReorderImage = ({
  id,
  allowedEdges,
  preview,
  previewDims,
}: {
  id: EditorProductImage['id'];
  allowedEdges: Edge[];
  preview?: () => JSX.Element;
  previewDims?: { width: number; height: number };
}) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);

  const [dragEdge, setDragEdge] = useState<Edge | null>(null);
  const [dragState, setDragState] = useState<'dragging' | 'dropped' | 'idle'>(
    'idle'
  );
  const [dragOverState, setDragOverState] = useState<'drag-over' | 'idle'>(
    'idle'
  );

  useEffect(() => {
    if (!draggableRef.current) return;

    return combine(
      draggable({
        element: draggableRef.current,
        dragHandle: dragHandleRef.current || draggableRef.current,
        getInitialData: () => ({
          type: 'image',
          id,
          instanceId: DRAG_REORDER_INSTANCE_ID,
        }),
        onDragStart: () => setDragState('dragging'),
        onDrop: () => setDragState('dropped'),
        ...(!!preview && {
          onGenerateDragPreview: ({ nativeSetDragImage }) => {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              ...(previewDims && {
                getOffset: () => ({
                  x: previewDims.width / 2,
                  y: previewDims.height / 2,
                }),
              }),
              render({ container }) {
                const root = createRoot(container);
                root.render(preview());
                return () => root.unmount();
              },
            });
          },
        }),
      }),
      dropTargetForElements({
        element: draggableRef.current,
        canDrop: ({ source }) =>
          source.data.instanceId === DRAG_REORDER_INSTANCE_ID,
        // this prevents us from losing the drop indicator when dragging over the gap between each target
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = { type: 'image', id };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges,
          });
        },
        onDragEnter: args => {
          setDragOverState('drag-over');
          setDragEdge(extractClosestEdge(args.self.data));
        },
        onDrag: args => {
          setDragEdge(extractClosestEdge(args.self.data));
        },
        onDragLeave: () => {
          setDragOverState('idle');
          setDragEdge(null);
        },
        onDrop: () => {
          setDragOverState('idle');
          setDragEdge(null);
        },
      })
    );
  }, [id, allowedEdges, preview, previewDims]);

  return { draggableRef, dragHandleRef, dragEdge, dragState, dragOverState };
};

export interface OnDropArgs {
  sourceId: unknown;
  targetId: unknown;
  edge: Edge | null;
}

export const useMonitorReorderImageDrop = (
  onDrop: (args: OnDropArgs) => void
) => {
  /**
   * The built-in window auto-scrolling works, but it requires too much precision from users.
   * Using this custom function gives much more user friendly results by scrolling when you get close to the edge.
   */
  useEffect(() => {
    return autoScrollWindowForElements();
  }, []);

  /**
   * Monitor drag and drop events for reordering images.
   */
  useEffect(() => {
    return monitorForElements({
      canMonitor: ({ source }) =>
        source.data.instanceId === DRAG_REORDER_INSTANCE_ID,
      onDrop: args => {
        const sourceId = args.source.data.id;
        const [target] = args.location.current.dropTargets;
        const targetId = target.data.id;
        const edge = extractClosestEdge(target.data);

        onDrop({ sourceId, targetId, edge });
      },
    });
  }, [onDrop]);
};

export const useSetUniqueImagePositions = () => {
  const change = useChangeProduct();

  const callback = useCallback(
    (withScreen?: boolean) => {
      return change({
        fieldId: 'images.unique-positions',
        label: 'Set Unique Image Positions',
        ...(withScreen && { screen: 'images-and-videos' }),
        apply: to => {
          let pos = 0;
          to.images = to.images
            ? to.images.map(image => ({
                ...image,
                position: pos++,
              }))
            : to.images;
        },
      });
    },
    [change]
  );

  return callback;
};
