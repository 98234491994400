import { useContext } from 'react';
import BulkEditContext from './context';

const useBulkEditContext = () => {
  const context = useContext(BulkEditContext);
  if (!context) {
    throw new Error(
      'useBulkEditContext must be used within a BulkEditProvider'
    );
  }
  return context;
};

export default useBulkEditContext;
