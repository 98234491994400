import type { Attribute } from '@odo/contexts/attributes';
import {
  attributeToSupplierOption,
  getAllSurcharges,
  getSupplierFromOption,
} from '@odo/helpers/attributes';
import { processVideoUrl } from '@odo/screens/deal/editor/images-and-videos/helpers';
import type { ApiCustomOption } from '@odo/types/api';
import { AttributeCode, type ApiCategoryBreadcrumb } from '@odo/types/api';
import type {
  CreateProductInput,
  GetProductInterface,
  UpdateProductInput,
} from '@odo/types/api-new';
import { isValidImage } from '@odo/types/guards';
import type {
  EditorProductVideo,
  EditorNumericInput,
  EditorCategory,
} from '@odo/types/portal';
import { SizeInfoImageKey } from '@odo/types/portal';
import {
  SkuAvailability,
  type EditorProductInterface,
} from '@odo/types/portal';
import { dateObjectTimeOnly, dateObjectToIso } from '@odo/utils/date';
import { isEmptyHTML } from '@odo/utils/html';
import { isNewId } from '@odo/utils/uuid';

const getImages = (images: GetProductInterface['images']) =>
  (images || [])
    .filter(isValidImage)
    .map(image => ({
      ...image,
      isHidden: image.excludeImageTypes === 1 ? true : false,
      position: image.position || 0,
    }))
    .sort((a, b) => a.position - b.position);

const getVideos = (videos: GetProductInterface['videos']) => {
  const editorVideos: EditorProductVideo[] = [];

  const matches = videos && videos.match(/<iframe.+?><\/.+?>/g);
  if (matches) {
    let pos = 0;
    const ids: string[] = [];
    matches.forEach(iframe => {
      if (ids.includes(iframe)) return; // we skip duplicates (they will be cleaned up on save)
      ids.push(iframe);
      const srcMatch = iframe.match(/src="(.+?)"/);
      editorVideos.push({
        // NOTE: for easier editing we need a reference ID
        // but this must match when we load the latest data before saving
        // we're gonna use the full iframe string for this, as it's the most unique
        // but that will mean we can't allow a product to have duplicates (unlikely to be an issue)
        id: iframe,
        raw: iframe,
        position: pos++,
        ...(srcMatch &&
          srcMatch[1] && {
            url: srcMatch[1],
            platform: processVideoUrl(srcMatch[1]).platform,
          }),
      });
    });
  }

  return editorVideos;
};

const numberToInput = (num: number | undefined): EditorNumericInput => ({
  string: num?.toString(),
  number: num,
});

/**
 * For some attributes "NONE" is an actual value for users to select,
 * for the rest it's the same as `undefined` or `null`.
 *
 * I believe this confusion is caused by some attributes being sent as "NONE" when empty,
 * while some attributes are actually as saved as "NONE" in MAG.
 *
 * We also have some attributes on BP FE which aren't saved as "NONE" in MAG,
 * but which we still want to allow the user select that value.
 *
 * NONE list: campaign, taxClass, buyInStockType
 */
const isNotNoneAttribute = (
  value: string | null | undefined
): value is string => !!value && value.toLowerCase() !== 'none';

/**
 * NOTE: most of these fields aren't actually queried via gql coz we don't use them.
 * But we've got them in the types for future proofing.
 */
const getInventory = (
  i: GetProductInterface['inventory']
): EditorProductInterface['inventory'] =>
  typeof i === 'undefined'
    ? undefined
    : {
        qty: numberToInput(i.qty),
        minQty: numberToInput(i.minQty),
        useConfigMinQty: i.useConfigMinQty,
        isQuantityDecimal: i.isQuantityDecimal,
        isBackorder: i.isBackorder,
        useConfigBackorder: i.useConfigBackorder,
        minSaleQuantity: numberToInput(i.minSaleQuantity),
        useConfigMinSaleQty: i.useConfigMinSaleQty,
        maximumSaleQuantity: numberToInput(i.maximumSaleQuantity),
        useConfigMaxSaleQty: i.useConfigMaxSaleQty,
        isInStock: i.isInStock,
        notifyStockQty: numberToInput(i.notifyStockQty),
        useConfigNotifyStockQty: i.useConfigNotifyStockQty,
        useConfigQuantityIncrement: i.useConfigQuantityIncrement,
        quantityIncrement: numberToInput(i.quantityIncrement),
        isDecimalDivide: i.isDecimalDivide,
        StockConfigQuantityIncrement: numberToInput(
          i.StockConfigQuantityIncrement
        ),
        isApplyMaxSaleQtyToProductOptions: i.isApplyMaxSaleQtyToProductOptions,
        isApplyMaxSaleQtyCustomerProfile: i.isApplyMaxSaleQtyCustomerProfile,
        // isManageStock on get, manageStock on create/update
        useConfigManageStock: i.isManageStock,
        // isEnableQuantityIncrements on get, enableQuantityIncrements on create/update
        useConfigEnableQuantityIncrements: i.isEnableQuantityIncrements,
      };

const getSizeInfo = (
  s: GetProductInterface['sizeChart'],
  attributes?: Attribute[]
): EditorProductInterface['sizeInfo'] => ({
  id: s?.id,
  recommendation: s?.recommendation,
  measurement: s?.measurement
    ? {
        id: s.measurement.toString(),
        label: attributes
          ?.find(attr => attr.id === AttributeCode.sizeChartMeasurements)
          ?.options.find(
            option => option.originalData.value === s.measurement?.toString()
          )?.label,
      }
    : undefined,
  mobile: {
    id: SizeInfoImageKey.mobile,
    label: 'Mobile',
    ...(typeof s?.mobile !== 'undefined' && {
      url: s.mobile.url,
      filePath: s.mobile.filePath,
    }),
  },
  tablet: {
    id: SizeInfoImageKey.tablet,
    label: 'Tablet',
    ...(typeof s?.tablet !== 'undefined' && {
      url: s.tablet.url,
      filePath: s.tablet.filePath,
    }),
  },
  desktop: {
    id: SizeInfoImageKey.desktop,
    label: 'Desktop',
    ...(typeof s?.desktop !== 'undefined' && {
      url: s.desktop.url,
      filePath: s.desktop.filePath,
    }),
  },
});

const splitCategories = (
  categories: GetProductInterface['categories'],
  breadcrumbs?: ApiCategoryBreadcrumb[]
) =>
  (categories || []).reduce(
    (acc, { categoryId, categoryName }) => {
      if (!categoryId) return acc;

      const breadcrumb = breadcrumbs
        ? breadcrumbs.find(b => b.id === categoryId)
        : undefined;

      if (breadcrumb && breadcrumb.type === 'daily_shop') {
        acc.shops.push({ id: categoryId, categoryName, breadcrumb });
      } else if (breadcrumb && breadcrumb.type === 'permanent') {
        acc.permanentShops.push({ id: categoryId, categoryName, breadcrumb });
      } else {
        acc.categories.push({ id: categoryId, categoryName, breadcrumb });
      }

      return acc;
    },
    {
      categories: [] as EditorCategory[],
      shops: [] as EditorCategory[],
      permanentShops: [] as EditorCategory[],
    }
  );

export const getProductToEditorProduct = ({
  product: p,
  attributes,
  breadcrumbs,
}: {
  product: GetProductInterface;
  customOptions?: ApiCustomOption[];
  attributes?: Attribute[];
  breadcrumbs?: ApiCategoryBreadcrumb[];
}): EditorProductInterface | undefined => {
  if (!p.id) {
    throw new Error('API product data is invalid.');
  }

  const editorProduct: EditorProductInterface = {
    id: p.id,
    preview: p.preview,
    isSupplierNew: p.isSupplierNew,
    brand: p.brand,
    sku: p.sku,
    url: p.url,
    name: p.name,
    shortName: p.shortName,
    isDisplayRetail: p.isDisplayRetail,
    isSavingsInRands: p.isSavingsInRands,
    isAlcoholic: p.isAlcoholic,
    isHygienic: p.isHygienic,
    isParallelImport: p.isParallelImport,
    isFragile: p.isFragile,
    additionalInfo: p.additionalInfo,
    calloutText: p.calloutText,
    lockdownText: p.lockdownText,
    isReturnableToSupplier: p.isReturnableToSupplier,
    warranty: p.warranty,
    leftAdditionalInfo: p.leftAdditionalInfo,
    moreDetails: p.moreDetails,
    status: p.status,
    isLunchtimeProduct: p.isLunchtimeProduct,
    isBestSeller: p.isBestSeller,
    isMainDeal: p.isMainDeal,
    isShippingApplied: p.isShippingApplied,
    isShippedIndividually: p.isShippedIndividually,
    pillOne: p.pillOne,
    pillTwo: p.pillTwo,
    isDeliveredBySupplier: p.isDeliveredBySupplier,
    surcharge: p.surcharge, // can be left as a number coz we always calculate it
    isSampleReceived: p.isSampleReceived,
    isPhotographedByStudio: p.isPhotographedByStudio,
    hasSalesHistory: p.hasSalesHistory,
    isXTD:
      typeof p.xtdDaysConfirmed === 'string' &&
      parseInt(p.xtdDaysConfirmed, 10) > 0,

    // remapped fields
    noStaffPurchases: p.isPreviewOnly,

    // self-executing anonymous function with a return statement
    // to get a variable for formatting without repeating work
    ...(() => {
      const active = {
        ...(p.activeFromDate && {
          activeFromDate: dateObjectToIso(new Date(p.activeFromDate), false),
          activeFromTime: dateObjectTimeOnly(new Date(p.activeFromDate), false),
        }),
        ...(p.activeToDate && {
          activeToDate: dateObjectToIso(new Date(p.activeToDate), false),
          activeToTime: dateObjectTimeOnly(new Date(p.activeToDate), false),
        }),
      };

      return {
        ...active,
        isTimedDeal:
          typeof active.activeFromTime !== 'undefined' &&
          typeof active.activeToTime !== 'undefined' &&
          (active.activeFromTime !== '00:00' ||
            active.activeToTime !== '23:59'),
      };
    })(),

    inventory: getInventory(p.inventory),
    sizeInfo: getSizeInfo(p.sizeChart, attributes),
    images: getImages(p.images),

    // TODO: customOptions (maybe)

    // TODO: isReferable & features (maybe)

    // custom/extra fields for editing
    skuAvailability: SkuAvailability.owned, // default to owned so that we don't unnecessarily run the checks

    // reformatted fields
    videos: getVideos(p.videos),

    // split the categories into shops, permanentShops, and categories
    ...splitCategories(p.categories, breadcrumbs),

    // numeric fields
    originalStock: numberToInput(p.originalStock),
    price: numberToInput(p.price),
    cost: numberToInput(p.cost),
    retail: numberToInput(p.retail),
    rebateDiscount: numberToInput(p.rebateDiscount),
    width: numberToInput(p.width),
    length: numberToInput(p.length),
    height: numberToInput(p.height),
    weight: numberToInput(p.weight),
    shippingCost: numberToInput(p.shippingCost),

    // completed attributes
    buyer: isNotNoneAttribute(p.buyer)
      ? {
          id: p.buyer,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.buyer)
            ?.options.find(option => option.value === p.buyer)?.label,
        }
      : undefined,
    salesAssistant: isNotNoneAttribute(p.salesAssistant)
      ? {
          id: p.salesAssistant,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.salesAssistant)
            ?.options.find(option => option.value === p.salesAssistant)?.label,
        }
      : undefined,
    priority: p.priority
      ? {
          id: p.priority,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.priority)
            ?.options.find(
              option => option.originalData.value === p.priority?.toString()
            )?.label,
        }
      : undefined,
    supplier: isNotNoneAttribute(p.supplier)
      ? {
          id: p.supplier,
          // self-executing anonymous function with a return statement
          // to get a variable for second field without repeating loops
          ...(() => {
            const attributeOption = attributes
              ?.find(attr => attr.id === AttributeCode.supplier)
              ?.options.find(option => option.value === p.supplier);

            return attributeOption
              ? getSupplierFromOption(
                  attributeToSupplierOption(attributeOption)
                )
              : {};
          })(),
        }
      : undefined,
    dealType: p.dealType
      ? p.dealType.map(dealType => ({
          id: dealType,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.dealType)
            ?.options.find(option => option.value === dealType)?.label,
        }))
      : undefined,
    campaign: p.campaign
      ? {
          id: p.campaign,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.campaign)
            ?.options.find(option => option.value === p.campaign)?.label,
        }
      : undefined,
    campaignMailer: p.campaignMailer
      ? p.campaignMailer
          // NOTE: the create mutations response will include `null` for some reason, so we need to filter that out.
          .filter(c => c !== null)
          .map(campaignMailer => ({
            id: campaignMailer,
            label: attributes
              ?.find(attr => attr.id === AttributeCode.campaignMailer)
              ?.options.find(option => option.value === campaignMailer)?.label,
          }))
      : undefined,
    platform: p.platform
      ? p.platform.map(platform => ({
          id: platform,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.platform)
            ?.options.find(option => option.value === platform)?.label,
        }))
      : undefined,
    condition: isNotNoneAttribute(p.condition)
      ? {
          id: p.condition,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.condition)
            ?.options.find(option => option.value === p.condition)?.label,
        }
      : undefined,
    taxClass: p.taxClass
      ? {
          id: p.taxClass,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.taxClass)
            ?.options.find(option => option.value === p.taxClass)?.label,
        }
      : undefined,
    area: isNotNoneAttribute(p.area)
      ? {
          id: p.area,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.area)
            ?.options.find(option => option.value === p.area)?.label,
        }
      : undefined,
    buyInStockType: p.buyInStockType
      ? {
          id: p.buyInStockType,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.buyInStockType)
            ?.options.find(option => option.value === p.buyInStockType)?.label,
        }
      : undefined,
    surcharges: getAllSurcharges({ surcharges: p.surcharges, attributes }),
    warrantyPeriod: isNotNoneAttribute(p.warrantyPeriod)
      ? {
          id: p.warrantyPeriod,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.warrantyPeriod)
            ?.options.find(option => option.value === p.warrantyPeriod)
            ?.label?.replace(/warrantyperiod/i, ''),
        }
      : undefined,
    supplierRepacks: isNotNoneAttribute(p.supplierRepacks)
      ? {
          id: p.supplierRepacks,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.supplierRepacks)
            ?.options.find(option => option.value === p.supplierRepacks)?.label,
        }
      : undefined,
    customerDeliveryTime: isNotNoneAttribute(p.customerDeliveryTime)
      ? {
          id: p.customerDeliveryTime,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.customerDeliveryTime)
            ?.options.find(option => option.value === p.customerDeliveryTime)
            ?.label,
        }
      : undefined,
    adminCost: isNotNoneAttribute(p.adminCost)
      ? {
          id: p.adminCost,
          label: attributes
            ?.find(attr => attr.id === AttributeCode.adminCost)
            ?.options.find(option => option.value === p.adminCost?.toString())
            ?.label,
        }
      : undefined,
  };

  return editorProduct;
};

export const editorProductToCreateProductInput = (
  p: EditorProductInterface,
  sizeChart: CreateProductInput['sizeChart']
): CreateProductInput | undefined => {
  // NOTE: these conditions don't seem to be informing TS that the fields are defined
  // but it should prevent runtime/API errors
  if (
    // required strings
    typeof p.brand === 'undefined' ||
    typeof p.sku === 'undefined' ||
    typeof p.name === 'undefined' ||
    typeof p.activeFromDate === 'undefined' ||
    typeof p.activeToDate === 'undefined' ||
    // required numbers
    typeof p.price?.number !== 'number' ||
    typeof p.cost?.number !== 'number' ||
    typeof p.retail?.number !== 'number' ||
    typeof p.width?.number !== 'number' ||
    typeof p.length?.number !== 'number' ||
    typeof p.height?.number !== 'number' ||
    typeof p.weight?.number !== 'number' ||
    // non-nullable numbers (these fields on EditorProductInterface are nullable for the sake of updates...)
    p.inventory?.qty?.number === null ||
    p.inventory?.minQty?.number === null ||
    p.inventory?.minSaleQuantity?.number === null ||
    p.inventory?.maximumSaleQuantity?.number === null ||
    p.inventory?.notifyStockQty?.number === null ||
    p.inventory?.quantityIncrement?.number === null ||
    p.inventory?.StockConfigQuantityIncrement?.number === null ||
    // required attributes
    typeof p.buyer?.id === 'undefined' ||
    typeof p.salesAssistant?.id === 'undefined' ||
    typeof p.warrantyPeriod?.id === 'undefined' ||
    typeof p.taxClass?.id === 'undefined' ||
    // required field in inventory object if present
    (typeof p.inventory !== 'undefined' &&
      typeof p.inventory.maximumSaleQuantity === 'undefined')
  ) {
    throw new Error('Product data cannot be transformed for creating.');
  }

  const createProductInput: CreateProductInput = {
    // hardcoded values
    attributeSet: 'ONEDAYONLY',
    type: 'SIMPLE',
    visibility: 4,
    shortDescription: '',

    // begin actual fields
    isSupplierNew: p.isSupplierNew,
    brand: p.brand,
    sku: p.sku,
    url: p.url,
    name: p.name,
    shortName: p.shortName,
    isDisplayRetail: p.isDisplayRetail,
    isSavingsInRands: p.isSavingsInRands,
    isAlcoholic: p.isAlcoholic,
    isHygienic: p.isHygienic,
    isParallelImport: p.isParallelImport,
    isFragile: p.isFragile,
    additionalInfo: p.additionalInfo,
    calloutText: p.calloutText,
    lockdownText: p.lockdownText,
    isReturnableToSupplier: p.isReturnableToSupplier,
    warranty: p.warranty,
    leftAdditionalInfo: p.leftAdditionalInfo,
    moreDetails:
      p.moreDetails && !isEmptyHTML(p.moreDetails) ? p.moreDetails : '',
    status: !!p.status,
    isLunchtimeProduct: p.isLunchtimeProduct,
    isBestSeller: p.isBestSeller,
    isMainDeal: p.isMainDeal,
    isShippingApplied: p.isShippingApplied,
    isShippedIndividually: p.isShippedIndividually,
    pillOne: p.pillOne,
    pillTwo: p.pillTwo,
    isDeliveredBySupplier: p.isDeliveredBySupplier,
    surcharge: p.surcharge,

    // remapped fields
    isPreviewOnly: p.noStaffPurchases,

    activeFromDate: dateObjectToIso(
      new Date(
        `${p.activeFromDate} ${p.isTimedDeal ? p.activeFromTime : '00:00'}`
      ),
      true
    ),
    activeToDate: dateObjectToIso(
      new Date(`${p.activeToDate} ${p.isTimedDeal ? p.activeToTime : '23:59'}`),
      true
    ),

    // required by the API, but biz doesn't seem to want them anymore (BP-852), so defaulting to false if missing
    isPhotographedByStudio: p.isPhotographedByStudio || false,
    isSampleReceived: p.isSampleReceived || false,

    // if the one required field from inventory isn't present, we won't send the inventory at all
    // again, we throw an error in this case, but we need the below for TS
    ...(typeof p.inventory?.maximumSaleQuantity?.number !== 'undefined'
      ? {
          inventory: {
            qty: p.inventory.qty?.number,
            minQty: p.inventory.minQty?.number,
            useConfigMinQty: p.inventory.useConfigMinQty,
            isQuantityDecimal: p.inventory.isQuantityDecimal,
            isBackorder: p.inventory.isBackorder,
            useConfigBackorder: p.inventory.useConfigBackorder,
            minSaleQuantity: p.inventory.minSaleQuantity?.number,
            useConfigMinSaleQty: p.inventory.useConfigMinSaleQty,
            maximumSaleQuantity: p.inventory.maximumSaleQuantity.number,
            useConfigMaxSaleQty: p.inventory.useConfigMaxSaleQty,
            isInStock: p.inventory.isInStock,
            notifyStockQty: p.inventory.notifyStockQty?.number,
            useConfigNotifyStockQty: p.inventory.useConfigNotifyStockQty,
            useConfigQuantityIncrement: p.inventory.useConfigQuantityIncrement,
            quantityIncrement: p.inventory.quantityIncrement?.number,
            isDecimalDivide: p.inventory.isDecimalDivide,
            StockConfigQuantityIncrement:
              p.inventory.StockConfigQuantityIncrement?.number,
            isApplyMaxSaleQtyToProductOptions:
              p.inventory.isApplyMaxSaleQtyToProductOptions,
            isApplyMaxSaleQtyCustomerProfile:
              p.inventory.isApplyMaxSaleQtyCustomerProfile,
            // isManageStock on get, manageStock on create/update
            manageStock: p.inventory.useConfigManageStock,
            // isEnableQuantityIncrements on get, enableQuantityIncrements on create/update
            useConfigEnableQuantityIncrements:
              p.inventory.useConfigEnableQuantityIncrements,
          },
        }
      : {}),

    // we need to prepare this data outside of this transformer because reading new files requires async
    sizeChart,

    // TODO: customOptions (maybe)

    images: (p.images || [])
      // we only want to send duplicating images in the create mutation
      // new images will be uploaded separately for now
      .filter(
        image => !isNewId(image.id) && image.filePath && !image.shouldDelete
      )
      .map(i => ({
        id: i.id,
        position: i.position,
        label: i.label,
        excludeImageTypes: i.isHidden ? 1 : 0,
        imageTypes: i.imageTypes,
        // NOTE: we filter out images without a filePath, but TS doesn't know that
        filePath: i.filePath || '',
        ...(!i.willBeConvertedToSizeInfo && { isDuplicate: true }),
      })),

    // TODO: isReferable & features (maybe)

    // reformatted fields
    videos: p.videos
      ? p.videos
          .filter(vid => !vid.shouldDelete)
          .sort((a, b) => a.position - b.position)
          .map(vid => vid.raw)
          .join(' ')
      : '',
    categories: [
      ...(p.categories || []),
      ...(p.shops || []),
      ...(p.permanentShops || []),
    ].reduce((acc, value) => {
      // NOTE: we need to convert the ID to a number and validate it
      const id = parseInt(value.id, 10);
      if (!isNaN(id)) {
        acc.push(id);
      }
      return acc;
    }, [] as number[]),

    // numeric fields
    price: p.price.number,
    cost: p.cost.number,
    retail: p.retail.number,
    width: p.width.number,
    length: p.length.number,
    height: p.height.number,
    weight: p.weight.number,
    originalStock: p.originalStock?.number || undefined,
    rebateDiscount: p.rebateDiscount?.number || undefined,
    shippingCost: p.shippingCost?.number || undefined,

    // attributes
    buyer: p.buyer?.id,
    salesAssistant: p.salesAssistant?.id,
    priority: p.priority?.id,
    supplier: p.supplier?.id,
    dealType:
      typeof p.dealType !== 'undefined' && p.dealType.length > 0
        ? p.dealType.map(({ id }) => id)
        : null, // need an empty array on update, but `null` on create
    campaign: p.campaign?.id,
    campaignMailer: p.campaignMailer?.map(({ id }) => id),
    platform: (p.platform || []).map(({ id }) => id),
    condition: p.condition?.id,
    taxClass: p.taxClass?.id,
    area: p.area?.id,
    buyInStockType: p.buyInStockType?.id,
    surcharges: (p.surcharges || []).map(({ id, value }) => ({
      key: id,
      value: value.string || '',
    })),
    warrantyPeriod: p.warrantyPeriod?.id,
    supplierRepacks: p.supplierRepacks?.id,
    customerDeliveryTime: p.customerDeliveryTime?.id,
    adminCost: p.adminCost?.id,
  };

  return createProductInput;
};

export const editorProductToUpdateProductInput = (
  p: EditorProductInterface,
  sizeChart: UpdateProductInput['sizeChart']
): UpdateProductInput | undefined => {
  // NOTE: these conditions don't seem to be informing TS that the fields are defined
  // but it should prevent runtime/API errors
  if (
    typeof p.inventory !== 'undefined' &&
    typeof p.inventory.maximumSaleQuantity === 'undefined'
  ) {
    throw new Error('Product data cannot be transformed for updating.');
  }

  const updateProductInput: UpdateProductInput = {
    isSupplierNew: p.isSupplierNew,
    brand: p.brand,
    sku: p.sku,
    url: p.url,
    name: p.name,
    shortName: p.shortName,
    isDisplayRetail: p.isDisplayRetail,
    isSavingsInRands: p.isSavingsInRands,
    isAlcoholic: p.isAlcoholic,
    isHygienic: p.isHygienic,
    isParallelImport: p.isParallelImport,
    isFragile: p.isFragile,
    additionalInfo: p.additionalInfo,
    calloutText: p.calloutText,
    lockdownText: p.lockdownText,
    isReturnableToSupplier: p.isReturnableToSupplier,
    warranty: p.warranty,
    leftAdditionalInfo: p.leftAdditionalInfo,
    moreDetails:
      p.moreDetails && !isEmptyHTML(p.moreDetails) ? p.moreDetails : '',
    status: p.status,
    isLunchtimeProduct: p.isLunchtimeProduct,
    isBestSeller: p.isBestSeller,
    isMainDeal: p.isMainDeal,
    isShippingApplied: p.isShippingApplied,
    isShippedIndividually: p.isShippedIndividually,
    pillOne: p.pillOne,
    pillTwo: p.pillTwo,
    isDeliveredBySupplier: p.isDeliveredBySupplier,
    surcharge: p.surcharge,

    // remapped fields
    isPreviewOnly: p.noStaffPurchases,

    activeFromDate: dateObjectToIso(
      new Date(
        `${p.activeFromDate} ${p.isTimedDeal ? p.activeFromTime : '00:00'}`
      ),
      true
    ),
    activeToDate: dateObjectToIso(
      new Date(`${p.activeToDate} ${p.isTimedDeal ? p.activeToTime : '23:59'}`),
      true
    ),

    // required by the API, but biz doesn't seem to want them anymore (BP-852), so defaulting to false if missing
    isPhotographedByStudio: p.isPhotographedByStudio || false,
    isSampleReceived: p.isSampleReceived || false,

    // if the one required field from inventory isn't present, we won't send the inventory at all
    // again, we throw an error in this case, but we need the below for TS
    ...(typeof p.inventory?.maximumSaleQuantity?.number !== 'undefined'
      ? {
          inventory: {
            qty: p.inventory.qty?.number,
            minQty: p.inventory.minQty?.number,
            useConfigMinQty: p.inventory.useConfigMinQty,
            isQuantityDecimal: p.inventory.isQuantityDecimal,
            isBackorder: p.inventory.isBackorder,
            useConfigBackorder: p.inventory.useConfigBackorder,
            minSaleQuantity: p.inventory.minSaleQuantity?.number,
            useConfigMinSaleQty: p.inventory.useConfigMinSaleQty,
            maximumSaleQuantity: p.inventory.maximumSaleQuantity.number,
            useConfigMaxSaleQty: p.inventory.useConfigMaxSaleQty,
            isInStock: p.inventory.isInStock,
            notifyStockQty: p.inventory.notifyStockQty?.number,
            useConfigNotifyStockQty: p.inventory.useConfigNotifyStockQty,
            useConfigQuantityIncrement: p.inventory.useConfigQuantityIncrement,
            quantityIncrement: p.inventory.quantityIncrement?.number,
            isDecimalDivide: p.inventory.isDecimalDivide,
            StockConfigQuantityIncrement:
              p.inventory.StockConfigQuantityIncrement?.number,
            isApplyMaxSaleQtyToProductOptions:
              p.inventory.isApplyMaxSaleQtyToProductOptions,
            isApplyMaxSaleQtyCustomerProfile:
              p.inventory.isApplyMaxSaleQtyCustomerProfile,
            // isManageStock on get, manageStock on create/update
            manageStock: p.inventory.useConfigManageStock,
            // isEnableQuantityIncrements on get, enableQuantityIncrements on create/update
            useConfigEnableQuantityIncrements:
              p.inventory.useConfigEnableQuantityIncrements,
          },
        }
      : {}),

    // we need to prepare this data outside of this transformer because reading new files requires async
    sizeChart,

    // TODO: customOptions (maybe)

    images: (p.images || [])
      // we only want to send existing images in the update mutation
      // new images will be uploaded separately for now
      .filter(image => !isNewId(image.id) && image.filePath)
      .map(i => ({
        id: i.id,
        position: i.position,
        label: i.label,
        excludeImageTypes: i.isHidden ? 1 : 0,
        imageTypes: i.imageTypes,
        // NOTE: we filter out images without a filePath, but TS doesn't know that
        filePath: i.filePath || '',
        ...(i.shouldDelete && { isDelete: true }),
      })),

    // TODO: isReferable & features (maybe)

    // reformatted fields
    videos: p.videos
      ? p.videos
          .filter(vid => !vid.shouldDelete)
          .sort((a, b) => a.position - b.position)
          .map(vid => vid.raw)
          .join(' ')
      : '',
    categories: [
      ...(p.categories || []),
      ...(p.shops || []),
      ...(p.permanentShops || []),
    ].reduce((acc, value) => {
      // NOTE: we need to convert the ID to a number and validate it
      const id = parseInt(value.id, 10);
      if (!isNaN(id)) {
        acc.push(id);
      }
      return acc;
    }, [] as number[]),

    // numeric fields
    originalStock: p.originalStock?.number,
    price: p.price?.number,
    cost: p.cost?.number,
    retail: p.retail?.number,
    rebateDiscount: p.rebateDiscount?.number,
    width: p.width?.number,
    length: p.length?.number,
    height: p.height?.number,
    weight: p.weight?.number,
    shippingCost: p.shippingCost?.number,

    // attributes
    buyer: p.buyer?.id,
    salesAssistant: p.salesAssistant?.id,
    priority: p.priority?.id,
    supplier: p.supplier?.id,
    dealType:
      typeof p.dealType !== 'undefined' && p.dealType.length > 0
        ? p.dealType.map(({ id }) => id)
        : [], // need an empty array on update, but `null` on create
    campaign: p.campaign?.id,
    campaignMailer: p.campaignMailer?.map(({ id }) => id),
    platform: p.platform?.map(({ id }) => id),
    condition: p.condition?.id,
    taxClass: p.taxClass?.id,
    area: p.area?.id,
    buyInStockType: p.buyInStockType?.id,
    surcharges: (p.surcharges || []).map(({ id, value }) => ({
      key: id,
      value: value.string || '',
    })),
    warrantyPeriod: p.warrantyPeriod?.id,
    supplierRepacks: p.supplierRepacks?.id,
    customerDeliveryTime: p.customerDeliveryTime?.id,
    adminCost: p.adminCost?.id,
  };

  return updateProductInput;
};
