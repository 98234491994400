import Dialog from '@odo/components/widgets/dialog';
import { Text } from '@odo/components/elements/typography';
import { Box, Flex, Grid } from '@odo/components/elements/layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CategoryTypeEnum } from '@odo/types/api';
import CategorySelector from '@odo/components/widgets/category-selector';
import styled from '@odo/lib/styled';
import { Label } from '@odo/components/elements/form-fields/shared-styles';
import { Input, Select, Switch } from '@odo/components/elements/form-fields';
import Divider from '@odo/components/elements/divider';
import Button from '@odo/components/elements/button';
import { cssColor } from '@odo/utils/css-color';
import Card from '@odo/components/elements/card';
import BulkEditLoader from './loader';
import type { BulkEditChanges } from './types';
import { type LoadingMessage } from './types';
import useBulkEditContext from '@odo/contexts/search/bulk-edit/hooks';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import Tooltip from '@odo/components/widgets/tooltip';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import usePreventCloseTab from '@odo/hooks/use-prevent-close-tab';
import { isEditorCategoryBreadcrumb } from '@odo/types/guards';

type BulkEditChangeField = keyof BulkEditChanges;

type BulkEditChangeValue<T extends BulkEditChangeField> = BulkEditChanges[T];

const SummaryCard = ({
  children,
  clear,
}: {
  children: React.ReactNode;
  clear: () => void;
}) => (
  <Card width="100%">
    <Flex
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      maxWidth="450px"
    >
      {children}
      <Button hue="blue" variant="flat" onClick={clear}>
        <FiX size="1.8rem" color={cssColor('palette-blue')} />
      </Button>
    </Flex>
  </Card>
);

const isDealLiveImmediately = (changes: BulkEditChanges) => {
  const activeFromDate = changes.activeFromDate
    ? new Date(changes.activeFromDate)
    : null;

  if (changes.enabled === false || !activeFromDate) return false;

  const today = new Date();

  return (
    activeFromDate.getFullYear() === today.getFullYear() &&
    activeFromDate.getMonth() === today.getMonth() &&
    activeFromDate.getDate() === today.getDate()
  );
};

const BulkEdit = () => {
  const {
    deals,
    isBulkEditDialogOpen,
    closeBulkEditDialog,
    saving,
    uploadChanges,
  } = useBulkEditContext();

  const [changes, setChanges] = useState<BulkEditChanges>({});
  const [loadingMessages, setLoadingMessages] = useState<
    Record<string, LoadingMessage>
  >({});
  const [showLiveWarning, setShowLiveWarning] = useState(false);
  const [liveWarningConfirmed, setLiveWarningConfirmed] = useState(false);

  const hasChanges = Object.values(changes).some(
    value => typeof value !== 'undefined'
  );

  const willGoLiveImmediately = useMemo(
    () => isDealLiveImmediately(changes),
    [changes]
  );

  useEffect(() => {
    if (!showLiveWarning) {
      setLiveWarningConfirmed(false);
    }
  }, [showLiveWarning]);

  // keep active to and active from dates in sync since we're only using "active date"
  useEffect(() => {
    if (!changes.activeFromDate) return;
    setChanges(prevChanges => ({
      ...prevChanges,
      activeToDate: changes.activeFromDate,
    }));
  }, [changes.activeFromDate]);

  const handleInput = useCallback(
    <T extends BulkEditChangeField>(
      field: T,
      value: BulkEditChangeValue<T>
    ) => {
      setChanges(prevChanges => ({
        ...prevChanges,
        [field]: value,
      }));
    },
    []
  );

  const handleClearField = useCallback(
    <T extends BulkEditChangeField>(field: T) => {
      setChanges(({ [field]: _, ...restChanges }) => ({ ...restChanges }));
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setShowLiveWarning(false);
    setLiveWarningConfirmed(false);
    closeBulkEditDialog();
  }, [closeBulkEditDialog]);

  const clearChanges = useCallback(() => {
    setChanges({});
    setShowLiveWarning(false);
    setLiveWarningConfirmed(false);
  }, []);

  const handleUpload = useCallback(async () => {
    await uploadChanges(changes, setLoadingMessages);
    clearChanges();
  }, [changes, clearChanges, uploadChanges]);

  // double check if user wants to leave tab with changes or if deals are uploading
  usePreventCloseTab({
    message: saving
      ? 'You are currently uploading changes in Bulk Edit'
      : 'You have unsaved changes in Bulk Edit',
    show: hasChanges || saving,
  });

  // make sure we don't unnecessarily submit the removeAllShops field
  useEffect(() => {
    if (changes.dailyShop) handleClearField('removeAllShops');
  }, [changes.dailyShop, handleClearField]);

  if (deals.length === 0) return null;

  return (
    <Dialog
      title={
        showLiveWarning
          ? "It's going live!"
          : saving
          ? 'Saving Deals...'
          : `Bulk Edit (${deals.length} deals selected)`
      }
      isOpen={isBulkEditDialogOpen}
      close={handleCloseDialog}
    >
      <Box maxWidth="800px">
        {saving ? (
          <BulkEditLoader loadingMessages={Object.values(loadingMessages)} />
        ) : showLiveWarning ? (
          <>
            <Grid gap="10px">
              <Flex width="100%" justifyContent="center">
                <IconExclamation
                  width={46}
                  height={46}
                  color={cssColor('palette-yellow')}
                />
              </Flex>

              <Text fontSize="16px" textAlign="center" mb="20px">
                Your current changes will cause some or all deals to go live
                immediately. <br /> Are you sure you want to proceed?
              </Text>
              <Text textAlign="center">
                Type "LIVE" in the box below to confirm and save your changes.
              </Text>
              <Input
                type="text"
                autoComplete="off"
                onChange={e => {
                  if (e.target.value === 'LIVE') {
                    setLiveWarningConfirmed(true);
                  } else {
                    setLiveWarningConfirmed(false);
                  }
                }}
                onBlur={e => {
                  if (e.target.value === 'LIVE') {
                    setLiveWarningConfirmed(true);
                  } else {
                    setLiveWarningConfirmed(false);
                  }
                }}
              />
            </Grid>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              mt="10px"
            >
              <Button
                hue="grey"
                variant="flat"
                onClick={() => setShowLiveWarning(false)}
                disabled={!hasChanges}
              >
                Go back
              </Button>
              <Text color="grey">{deals.length} deals will be affected</Text>
              <Button
                hue="blue"
                variant="solid"
                onClick={handleUpload}
                disabled={hasChanges && !liveWarningConfirmed}
              >
                Confirm and save
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Grid
              gridTemplateColumns="repeat(2, 1fr)"
              p="10px"
              gap="20px"
              minWidth="800px"
              mb="10px"
            >
              {/* TODO: use gridTemplateAreas above to avoid this additional Flex */}
              <Flex flexDirection="column" gap="20px">
                <Select
                  width="100%"
                  label="Status"
                  value={
                    typeof changes.enabled !== 'undefined'
                      ? changes?.enabled?.toString()
                      : undefined
                  }
                  onChange={e => {
                    e.target.value !== 'No change'
                      ? handleInput('enabled', e.target.value === 'true')
                      : handleClearField('enabled');
                  }}
                  options={[
                    { id: 'undefined', label: 'No change', value: undefined },
                    { id: 'true', label: 'Enabled', value: 'true' },
                    { id: 'false', label: 'Disabled', value: 'false' },
                  ]}
                />
                <Input
                  type="date"
                  value={changes.activeFromDate || ''}
                  label="Active date"
                  onChange={e => handleInput('activeFromDate', e.target.value)}
                />
              </Flex>

              <Flex flexDirection="column" gap="10px">
                <CategorySelector
                  label="Daily shop"
                  placeholder="Search daily shops..."
                  categoryType={CategoryTypeEnum.dailyShop}
                  addCategory={c => handleInput('dailyShop', c)}
                  removeCategory={() => handleClearField('dailyShop')}
                  categories={[]}
                  zIndex={100}
                />
                {changes.dailyShop && (
                  <Flex
                    backgroundColor={cssColor('background')}
                    width="100%"
                    p="6px"
                    borderRadius="4px"
                  >
                    <Text>
                      Selected Shop:{' '}
                      {isEditorCategoryBreadcrumb(changes.dailyShop)
                        ? changes.dailyShop.breadcrumb
                        : 'No daily shop'}
                    </Text>
                  </Flex>
                )}

                <Button
                  disabled={!!changes.dailyShop}
                  hue="red"
                  width="100%"
                  variant="outlined"
                  justifyContent="space-between"
                  onClick={() => {
                    if (!!changes.removeAllShops) {
                      handleClearField('removeAllShops');
                    } else {
                      handleInput('removeAllShops', true);
                    }
                  }}
                >
                  Remove all daily shops
                  <Switch
                    readOnly
                    checked={!!changes.removeAllShops}
                    disabled={!!changes.dailyShop}
                  />
                </Button>
              </Flex>
            </Grid>

            <Divider />
            <Box mt="20px">
              <Label>Summary of changes to be applied:</Label>
              <Grid
                gap="10px"
                width="100%"
                gridTemplateColumns="repeat(2, 1fr)"
                py="20px"
              >
                {typeof changes.enabled === 'boolean' && (
                  <SummaryCard clear={() => handleClearField('enabled')}>
                    {changes.enabled ? 'Enable' : 'Disable'} all deals
                  </SummaryCard>
                )}
                {changes.dailyShop && (
                  <SummaryCard clear={() => handleClearField('dailyShop')}>
                    <Tooltip
                      showDelay={500}
                      hideDelay={100}
                      content={() =>
                        'All daily shops will be removed and replaced with the selected shop.'
                      }
                      color={cssColor('palette-blue')}
                      placement="left"
                    >
                      <FiAlertCircle
                        size="1.8rem"
                        color={cssColor('palette-blue')}
                      />
                    </Tooltip>
                    Daily shop changed to{' '}
                    {changes?.dailyShop?.breadcrumb?.toString()}
                  </SummaryCard>
                )}
                {changes.removeAllShops && (
                  <SummaryCard clear={() => handleClearField('removeAllShops')}>
                    Remove all daily shops
                  </SummaryCard>
                )}
                {changes.activeFromDate && (
                  <SummaryCard clear={() => handleClearField('activeFromDate')}>
                    {willGoLiveImmediately && (
                      <Tooltip
                        showDelay={500}
                        hideDelay={100}
                        content={() =>
                          'Deals will go live immediately after the changes are saved'
                        }
                        color={cssColor('palette-yellow')}
                        placement="left"
                      >
                        <FiAlertCircle
                          size="1.8rem"
                          color={cssColor('palette-yellow')}
                        />
                      </Tooltip>
                    )}
                    Active date changed to {changes.activeFromDate}
                  </SummaryCard>
                )}
              </Grid>
            </Box>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
            >
              <Button
                hue="grey"
                variant="flat"
                onClick={clearChanges}
                disabled={!hasChanges}
              >
                Reset
              </Button>
              <Text color="grey">{deals.length} deals will be affected</Text>
              <Button
                hue="blue"
                variant="solid"
                onClick={() =>
                  willGoLiveImmediately
                    ? setShowLiveWarning(true)
                    : handleUpload()
                }
                disabled={!hasChanges}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default BulkEdit;
