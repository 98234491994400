import { Grid } from '@odo/components/elements/layout';
import { useChangeProduct } from '@odo/contexts/product-editor';
import { useCallback } from 'react';
import { VideoGridItem } from '.';
import { swapItem } from '@odo/utils/array';
import type { EditorProductVideo } from '@odo/types/portal';

const VideoGrid = ({
  sortedVideos,
  selected,
  toggleSelected,
}: {
  sortedVideos: EditorProductVideo[];
  selected: EditorProductVideo['id'][];
  toggleSelected: (id: EditorProductVideo['id']) => (select: boolean) => void;
}) => {
  const change = useChangeProduct();

  const swapPositions = useCallback(
    (indexA: number, indexB: number) => {
      swapItem({
        list: sortedVideos,
        from: indexA,
        to: indexB,
        getPosition: video => video.position || 0,
        setPosition: (video, position) => {
          change({
            fieldId: `videos.${video.id}.position`,
            label: 'Video Position',
            screen: 'images-and-videos',
            apply: to => {
              to.videos = to.videos
                ? to.videos.map(i =>
                    i.id === video.id ? { ...i, position } : i
                  )
                : to.videos;
            },
          });
        },
      });
    },
    [sortedVideos, change]
  );

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
      gap={[2, 3]}
    >
      {sortedVideos.map((video, idx) => (
        <VideoGridItem
          key={video.id}
          video={video}
          isSelected={selected.includes(video.id)}
          toggleSelected={toggleSelected(video.id)}
          canMoveRight={idx < sortedVideos.length - 1}
          canMoveLeft={idx > 0}
          moveRight={() => swapPositions(idx, idx + 1)}
          moveLeft={() => swapPositions(idx, idx - 1)}
        />
      ))}
    </Grid>
  );
};

export default VideoGrid;
