import { getODOAdminUrl } from '@odo/utils/make-url';
import type { GridCategory, GridProduct } from './types';
import type { ApiCategoryBreadcrumb, ApiProduct } from '@odo/types/api';
import { cacheGet } from '@odo/utils/cache';
import { IMAGE_CACHE_NAME } from './constants';
import type { GetProductInterface } from '@odo/types/api-new';

const splitCategories = (
  categories: GetProductInterface['categories'],
  breadcrumbs: ApiCategoryBreadcrumb[]
) =>
  (categories || []).reduce(
    (acc, { categoryId, categoryName }) => {
      if (!categoryId) return acc;

      const breadcrumb = breadcrumbs
        ? breadcrumbs.find(b => b.id === categoryId)
        : undefined;

      if (breadcrumb && breadcrumb.type === 'daily_shop') {
        acc.shops.push({ id: categoryId, categoryName, breadcrumb });
      } else if (breadcrumb && breadcrumb.type === 'permanent') {
        acc.permanentShops.push({ id: categoryId, categoryName, breadcrumb });
      } else {
        acc.categories.push({ id: categoryId, categoryName, breadcrumb });
      }

      return acc;
    },
    {
      categories: [] as GridCategory[],
      shops: [] as GridCategory[],
      permanentShops: [] as GridCategory[],
    }
  );

export const prepProductRow: (
  product: ApiProduct,
  breadcrumbs?: ApiCategoryBreadcrumb[]
) => {
  rowId: string;
  data: GridProduct;
} = (product, breadcrumbs) => {
  const shopsAndCategories = breadcrumbs
    ? splitCategories(product.categories, breadcrumbs)
    : {
        categories:
          product.categories?.map(c => ({
            id: c.categoryId,
            categoryName: c.categoryName,
          })) || [],
        shops: [],
        permanentShops: [],
      };

  return {
    rowId: product.id,
    data: {
      id: product.id,
      status: product.status,
      image:
        product['thumbnail'] ||
        cacheGet({ cacheName: IMAGE_CACHE_NAME, cacheKey: product.id }),
      name: product.name,
      brand: product.brand,
      sku: product.sku,
      shops: shopsAndCategories.shops,
      categories: shopsAndCategories.categories,
      permanentShops: shopsAndCategories.permanentShops,
      cost: product.cost,
      price: product.price,
      retail: product.retail,
      qty: product['inventory']['qty'] || null,
      activeFromDate: product.activeFromDate || null,
      activeToDate: product.activeToDate || null,
      buyer: product.buyer || 'N/A',
      salesAssistant: product.salesAssistant || 'N/A',
      priority: product.priority || null,
      preview: product.preview || null,
      priceForecastUrl: getODOAdminUrl({
        path: `/price_calculator?deal=${product.id}`,
      }),
      isSampleReceived: product.isSampleReceived,
      isPhotographedByStudio: product.isPhotographedByStudio,
      dealType: product.dealType,
      campaign: product.campaign,
      xtdDaysConfirmed: product.xtdDaysConfirmed,
      xtdDaysRequested: product.xtdDaysRequested,
    },
  };
};
