import { Box, Flex, Grid } from '@odo/components/elements/layout';
import type { TextProps } from '@odo/components/elements/typography';
import { Heading, Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import { useVariationList } from '@odo/contexts/custom-options-editor';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import type { ReactNode } from 'react';
import { Fragment, useMemo } from 'react';
import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import type { FormatMoneyOptions } from '@odo/utils/currency';
import { formatMoney } from '@odo/utils/currency';
import {
  useSavings,
  useProfitCalculation,
  useValidity,
} from '@odo/screens/deal/editor/hooks';
import StatusMessages from '@odo/screens/deal/editor/widgets/status-messages';
import { getCategoryLabel } from '@odo/screens/deal/editor/conditions-and-category/helpers';
import {
  getAdminUrl,
  isProductDateLive,
} from '@odo/screens/deal/editor/helpers';
import ButtonLink from '@odo/components/elements/button/link';
import Tooltip from '@odo/components/widgets/tooltip';
import { ImageType } from '@odo/types/api';
import ImagePreview from '@odo/screens/deal/editor/widgets/images/image-preview';
import { dateObjectToIso } from '@odo/utils/date';
import { FiAlertCircle as IconAlert } from 'react-icons/fi';
import {
  POTENTIAL_PROFIT_TOOLTIP_COPY,
  WEIGHTED_MARGIN_TOOLTIP_COPY,
} from '@odo/screens/deal/editor/constants';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import SkuStatus from '@odo/screens/deal/editor/widgets/sku-status';

const NA = () => <Text fontStyle="italic">N/A</Text>;

const moneyOrNA = (
  value: number | null | undefined,
  options?: FormatMoneyOptions
) => (typeof value === 'number' ? formatMoney(value, options) : <NA />);

const percentageOrNA = (value: number | null | undefined) =>
  typeof value === 'number' ? `${value}%` : <NA />;

type DataListItem =
  | [ReactNode, ReactNode]
  | [ReactNode, ReactNode, 'good' | 'error' | 'warning' | undefined];

const DataList = ({ items }: { items: DataListItem[] }) => (
  <Grid gap={[0, 2]} gridTemplateColumns={['1fr', 'auto 1fr']}>
    {items.map(([label, value, status], idx) => (
      <Fragment key={idx}>
        <Text mt={idx > 0 ? [2, 0] : undefined}>{label}</Text>
        <Text
          fontWeight={800}
          lineHeight="1.5"
          textAlign={['left', 'right']}
          color={
            status === 'good'
              ? cssColor('palette-turquoise')
              : status === 'error'
              ? cssColor('palette-pink')
              : status === 'warning'
              ? cssColor('palette-yellow')
              : undefined
          }
        >
          {value}
        </Text>
      </Fragment>
    ))}
  </Grid>
);

const ValidationSection = () => {
  const validity = useValidity();
  const { status } = validity;

  return (
    <SectionWrapper title="Validation" status={status}>
      <Grid gap={[3, 4]}>
        <StatusMessages validity={validity} />
      </Grid>
    </SectionWrapper>
  );
};

const ImageAndLinksSection = () => {
  const currentProduct = useCurrentProduct();

  const websiteImage = useMemo(
    () =>
      (currentProduct.images || []).find(image =>
        (image.imageTypes || []).includes(ImageType.website)
      ),
    [currentProduct.images]
  );

  return (
    <SectionWrapper title="Image & Links">
      <Grid
        gridTemplateColumns={['1fr', 'auto 1fr']}
        gap={[3, 4]}
        justifyContent="flex-start"
        justifyItems={['center', 'flex-start']}
      >
        <ImagePreview
          url={websiteImage?.url}
          file={websiteImage?.file}
          dimensions={130}
        />

        <Flex
          gap={[2, 3]}
          flexDirection="column"
          alignItems={['center', 'flex-start']}
        >
          <ButtonLink
            hue="blue"
            variant="solid"
            target="_blank"
            href={getAdminUrl({
              dest: 'accountManagerSignOff',
              productId: currentProduct.id,
            })}
          >
            Account Manager Sign-Off
          </ButtonLink>

          <ButtonLink
            hue="blue"
            variant="solid"
            target="_blank"
            href={getAdminUrl({
              dest: 'supplierSignOff',
              productId: currentProduct.id,
            })}
          >
            Supplier Sign-Off
          </ButtonLink>

          <Tooltip
            disabled={
              currentProduct.status &&
              !!currentProduct.id &&
              !!currentProduct.preview
            }
            showDelay={250}
            hideDelay={250}
            content={() =>
              !currentProduct.id
                ? 'Cannot preview before the deal is created.'
                : !currentProduct.preview
                ? 'Cannot preview until a preview URL is generated.'
                : 'Cannot preview when the deal is disabled.'
            }
          >
            <ButtonLink
              hue="blue"
              variant="solid"
              disabled={
                !currentProduct.status ||
                !currentProduct.id ||
                !currentProduct.preview
              }
              href={currentProduct.preview || ''}
              target="_blank"
            >
              Open Preview
            </ButtonLink>
          </Tooltip>
        </Flex>
      </Grid>
    </SectionWrapper>
  );
};

const ManagerAndLoaderSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Account Manager & Loader">
      <DataList
        items={[
          ['Account Manager', currentProduct.buyer?.label || <NA />],
          ['Loader', currentProduct.salesAssistant?.label || <NA />],
        ]}
      />
    </SectionWrapper>
  );
};

const SupplierSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Supplier">
      <DataList
        items={[
          ['Supplier', currentProduct.supplier?.label || <NA />],
          ['Area', currentProduct.area?.label || <NA />],
          [
            'Supplier Repacks?',
            currentProduct.supplierRepacks?.label || <NA />,
          ],
          [
            'Supplier Delivers?',
            currentProduct.isDeliveredBySupplier ? 'Yes' : 'No',
          ],
        ]}
      />
    </SectionWrapper>
  );
};

const ProductSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Product">
      <DataList
        items={[
          ['Brand', currentProduct.brand || <NA />],
          ['Name', currentProduct.name || <NA />],
          ['Short Name', currentProduct.shortName || <NA />],
        ]}
      />

      <DataList
        items={[
          ['Pill One', currentProduct.pillOne || <NA />],
          ['Pill Two', currentProduct.pillTwo || <NA />],
        ]}
      />
    </SectionWrapper>
  );
};

const InventorySection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Inventory">
      <DataList
        items={[
          [
            'Inventory Status',
            currentProduct.inventory?.isInStock ? 'In Stock' : 'Out of Stock',
          ],
          [
            'Quantity Available',
            currentProduct.inventory?.qty?.string || <NA />,
          ],
          ['Original Stock', currentProduct.originalStock?.string || <NA />],
        ]}
      />
    </SectionWrapper>
  );
};

const DateAndTimeSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Date & Time">
      <DataList
        items={[
          ['Is Timed Deal', currentProduct.isTimedDeal ? 'Yes' : 'No'],
          [
            'From Date',
            currentProduct.isTimedDeal
              ? dateObjectToIso(
                  new Date(
                    `${currentProduct.activeFromDate} ${currentProduct.activeFromTime}`
                  ),
                  true
                )
              : currentProduct.activeFromDate || <NA />,
            isProductDateLive(currentProduct) ? 'warning' : undefined,
          ],
          [
            'To Date',
            currentProduct.isTimedDeal
              ? dateObjectToIso(
                  new Date(
                    `${currentProduct.activeToDate} ${currentProduct.activeToTime}`
                  ),
                  true
                )
              : currentProduct.activeToDate || <NA />,
            isProductDateLive(currentProduct) ? 'warning' : undefined,
          ],
        ]}
      />
    </SectionWrapper>
  );
};

const CostingInfoSection = () => {
  const currentProduct = useCurrentProduct();

  const savings = useSavings();
  const profit = useProfitCalculation();

  return (
    <SectionWrapper title="Costing Info">
      <DataList
        items={[
          ['Cost Price', moneyOrNA(currentProduct.cost?.number)],
          ['ODO Price', moneyOrNA(currentProduct.price?.number)],
          [
            'Customer Savings',
            currentProduct.isSavingsInRands
              ? moneyOrNA(savings?.fixed, { decimals: 0 })
              : percentageOrNA(savings?.percentage),
          ],
          ['Retail Price', moneyOrNA(currentProduct.retail?.number)],
        ]}
      />

      <DataList
        items={[
          ['Surcharge', moneyOrNA(currentProduct.surcharge)],
          ['Rebate', percentageOrNA(currentProduct.rebateDiscount?.number)],
        ]}
      />

      <DataList
        items={[
          [
            <Tooltip
              showDelay={250}
              content={() => WEIGHTED_MARGIN_TOOLTIP_COPY}
            >
              <Flex gap={[1, 2]}>
                Weighted Margin{' '}
                <IconAlert size={16} color={cssColor('palette-blue')} />
              </Flex>
            </Tooltip>,
            moneyOrNA(profit?.weightedAverage?.raw),
            profit?.weightedAverage?.raw && profit.weightedAverage.raw < 0
              ? 'error'
              : undefined,
          ],
          [
            <Tooltip
              showDelay={250}
              content={() => POTENTIAL_PROFIT_TOOLTIP_COPY}
            >
              <Flex gap={[1, 2]}>
                Potential Profit{' '}
                <IconAlert size={16} color={cssColor('palette-blue')} />
              </Flex>
            </Tooltip>,
            moneyOrNA(profit?.profitPotential),
            profit?.profitPotential && profit.profitPotential < 0
              ? 'error'
              : undefined,
          ],
        ]}
      />
    </SectionWrapper>
  );
};

/**
 * NOTE: technically the shop field can have multiple entries, but biz practice is to only select one.
 * So we're labeling things in the singular, but can't afford to hide it if there's more than one.
 */
const ShopAndCategoriesSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="Shop & Categories">
      <Grid gap={1}>
        <Text>Shop</Text>
        <Flex flexDirection="column" gap={2}>
          {(currentProduct.shops || []).length === 0 && <NA />}
          {(currentProduct.shops || []).map(shop => (
            <Text key={shop.id} fontWeight={800}>
              {getCategoryLabel(shop)}
            </Text>
          ))}
        </Flex>
      </Grid>

      <Grid gap={1}>
        <Text>Categories</Text>
        <ul style={{ paddingInlineStart: '16px', marginBlock: 0 }}>
          {(currentProduct.categories || []).map(category => (
            <li key={category.id} style={{ padding: '4px 0', fontWeight: 800 }}>
              {getCategoryLabel(category)}
            </li>
          ))}
        </ul>
      </Grid>
    </SectionWrapper>
  );
};

const SkuLabel = ({
  children,
  fontWeight,
}: {
  children: ReactNode;
  fontWeight?: TextProps['fontWeight'];
}) => (
  <Text fontFamily="mono" fontWeight={fontWeight}>
    {children}
  </Text>
);

/**
 * NOTE: "sku additions" in the RP version used to mean "variations". this was their code for it:
 * const nodes = currentDeal.deal.variations.getTopLevelNodes('');
 * const treeData = MapVariationsToTreeData(nodes, {}, deal, renderTreeItem);
 *
 * the code was removed before my time. on 2022-03-09 in this commit:
 * @see https://bitbucket.org/onedayonly/buyers-portal-fe/commits/82c8267b73b0eb3acdf98258359f7833f2222317
 *
 * We might at some point want to add our own "additions" to this section.
 * But I doubt we'll ever want to put variations here.
 */
const SkuAndAdditionsSection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title="SKU">
      <Flex flexDirection="column" gap={1}>
        <SkuLabel fontWeight={[800, 500]}>
          {currentProduct.sku || <NA />}
        </SkuLabel>
        <SkuStatus />
      </Flex>
    </SectionWrapper>
  );
};

const OptionPill = styled.span`
  background: ${cssColor('muted')};
  padding: 1px 3px;
  border-radius: 4px;
`;

/**
 * NOTE: I decided to render two different versions of the variations at the same time.
 * One for mobile, and one for tablet & desktop.
 * This was necessary as the grid we use on larger screens just wouldn't work on a mobile device.
 *
 * Fortunately, nothing rendered on this screen is resource intensive, so I think we can get away with it.
 * But please don't blindly emulate this implementation elsewhere, as most of the rest of the app is more intensive.
 */
const VariationsSection = () => {
  const currentProduct = useCurrentProduct();

  const baseSku = useMemo(() => {
    const sku = currentProduct.sku || '';
    const pipeIndex = sku.indexOf('|');
    if (pipeIndex === -1) return sku;
    return sku.slice(0, pipeIndex);
  }, [currentProduct.sku]);

  const variationList = useVariationList(baseSku);

  if (variationList.length === 0) {
    return (
      <SectionWrapper title="Variations">
        <NA />
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper title="Variations">
      {/* mobile */}
      <Box display={[null, 'none']}>
        <Grid gridTemplateColumns="1fr" rowGap={3}>
          {variationList.map((variation, idx) => (
            <Box
              key={variation.id}
              pt={idx > 0 ? 3 : 0}
              style={{
                ...(idx > 0 && {
                  borderTop: `1px solid ${cssColor('border')}`,
                }),
              }}
            >
              <DataList
                items={[
                  [
                    'Variation',
                    variation.label.map((part, index) => (
                      <Fragment key={index}>
                        <OptionPill>{part.option}</OptionPill>: {part.value}
                        {index < variation.label.length - 1 && <>, </>}
                      </Fragment>
                    )),
                  ],
                  [
                    'SKU',
                    <SkuLabel fontWeight={800}>{variation.sku}</SkuLabel>,
                  ],
                  [
                    'SKU Unique',
                    <>
                      {!variation.isUniqueSKU ? (
                        <IconExclamation
                          color={cssColor('palette-pink')}
                          width={16}
                          height={16}
                        />
                      ) : (
                        <IconValid
                          color={cssColor('palette-turquoise')}
                          width={16}
                          height={16}
                        />
                      )}
                    </>,
                  ],
                  ['Quantity', variation.qty],
                ]}
              />
            </Box>
          ))}
        </Grid>
      </Box>

      {/* tablet & desktop */}
      <Box display={['none', 'unset']}>
        <Grid
          gridTemplateColumns={['1fr', 'auto auto auto auto']}
          justifyContent={['start', 'unset']}
          columnGap={[3, 4]}
          rowGap={[2, 3]}
        >
          <Heading fontSize={2}>Variation</Heading>
          <Heading fontSize={2}>SKU</Heading>
          <Heading fontSize={2}>SKU Unique</Heading>
          <Heading fontSize={2}>Quantity</Heading>

          {variationList.map(variation => (
            <Fragment key={variation.id}>
              <Text lineHeight={1.5}>
                {variation.label.map((part, index) => (
                  <Fragment key={index}>
                    <OptionPill>{part.option}</OptionPill>: {part.value}
                    {index < variation.label.length - 1 && <>, </>}
                  </Fragment>
                ))}
              </Text>

              <SkuLabel>{variation.sku}</SkuLabel>

              {!variation.isUniqueSKU ? (
                <IconExclamation
                  color={cssColor('palette-pink')}
                  width={16}
                  height={16}
                />
              ) : (
                <IconValid
                  color={cssColor('palette-turquoise')}
                  width={16}
                  height={16}
                />
              )}

              <Text>{variation.qty}</Text>
            </Fragment>
          ))}
        </Grid>
      </Box>
    </SectionWrapper>
  );
};

/**
 * NOTE: I decided to render some of the sections twice. Once for mobile & tablet, and once for desktop.
 * This was to allow us to get as close as possible to a "masonry" layout with pure CSS.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Masonry_layout
 *
 * Fortunately, nothing rendered on this screen is resource intensive, so I think we can get away with it.
 * But please don't blindly emulate this implementation elsewhere, as most of the rest of the app is more intensive.
 */
const SummaryScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns="1fr" gap={[3, 4]}>
      <Grid gridTemplateColumns={['1fr', null, '1fr 1fr 1fr']} gap={[3, 4]}>
        {/* mobile and tablet */}
        <Grid
          gridTemplateColumns="1fr"
          gap={[3, 4]}
          alignContent="flex-start"
          display={[null, null, 'none']}
        >
          <ImageAndLinksSection />
          <ValidationSection />
          <ManagerAndLoaderSection />
          <SupplierSection />
          <ProductSection />
          <InventorySection />
          <DateAndTimeSection />
        </Grid>

        {/* desktop */}
        <Grid
          gridTemplateColumns="1fr"
          gap={[3, 4]}
          alignContent="flex-start"
          display={['none', null, 'grid']}
        >
          <ImageAndLinksSection />
          <ManagerAndLoaderSection />
          <SupplierSection />
          <InventorySection />
        </Grid>

        <Grid
          gridTemplateColumns="1fr"
          gap={[3, 4]}
          alignContent="flex-start"
          display={['none', null, 'grid']}
        >
          <ValidationSection />
          <ProductSection />
          <DateAndTimeSection />
        </Grid>

        {/* all screens */}
        <Grid gridTemplateColumns="1fr" gap={[3, 4]} alignContent="flex-start">
          <CostingInfoSection />
          <ShopAndCategoriesSection />
          <SkuAndAdditionsSection />
        </Grid>
      </Grid>

      <VariationsSection />
    </Grid>

    <Overscroll />
  </ErrorBoundary>
);

export default SummaryScreen;
