import type { Validator } from '@odo/screens/deal/editor/types';
import { Status } from '@odo/screens/deal/editor/types';
import type { EditorProductInterface } from '@odo/types/portal';

export interface Validity {
  errors: Validator['message'][];
  warnings: Validator['message'][];
  status: Status;
}

export const validateProduct = (
  product: EditorProductInterface,
  validators: Validator[]
): Validity => {
  const errors: Validator['message'][] = [];
  const warnings: Validator['message'][] = [];

  validators.forEach(({ validate, message, invertValidation, status }) => {
    const res = validate(product);
    // normal behaviour is that true means validation is successful
    // but this can be inverted on a per-validator basis
    if ((!invertValidation && res) || (invertValidation && !res)) return;

    if (status === Status.error) {
      errors.push(message);
    } else if (status === Status.warning) {
      warnings.push(message);
    }
  });

  return {
    errors,
    warnings,
    status:
      errors.length > 0
        ? Status.error
        : warnings.length > 0
        ? Status.warning
        : Status.valid,
  };
};

export const isProductDateLive = (product: EditorProductInterface): boolean => {
  if (!product.activeFromDate || !product.activeToDate) return false;

  const startDate = product.isTimedDeal
    ? new Date(`${product.activeFromDate} ${product.activeFromTime}`)
    : new Date(product.activeFromDate);
  const endDate = product.isTimedDeal
    ? new Date(`${product.activeToDate} ${product.activeToTime}`)
    : new Date(`${product.activeToDate} 23:59:59`);

  const today = new Date();
  return today > startDate && today < endDate;
};

const skuRegex = new RegExp(/^[A-Z][A-Z0-9\-_&.+/\\]+[|0-9]*$/i);
const variationSkuRegex = new RegExp(/^[A-Z0-9\-_&.+/\\]+$/i);

export const isSkuValid = (sku: string) => skuRegex.test(sku);
export const isVariationSkuValid = (sku: string) => variationSkuRegex.test(sku);

export const getAdminUrl = ({
  dest,
  productId,
}: {
  dest: 'priceCalculator' | 'accountManagerSignOff' | 'supplierSignOff';
  productId?: string;
}) => {
  switch (dest) {
    case 'priceCalculator':
      if (!productId) return '';
      return `${process.env.REACT_APP_ODO_BASE_URL}/price_calculator?deal=${productId}`;
    case 'accountManagerSignOff':
      if (!productId) return '';
      return `${process.env.REACT_APP_ODO_SIGNOFF_BASE_URL}/buyer?product_id=${productId}`;
    case 'supplierSignOff':
      if (!productId) return '';
      return `${process.env.REACT_APP_ODO_SIGNOFF_BASE_URL}?product_id=${productId}`;
    default:
      return '';
  }
};
